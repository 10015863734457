<app-header></app-header>
  <div class="content" role="main">
    <div class="container-fluid">
      <div class="top-cards">
      <div class="row">

        <div class="col-lg-6">
          <div id="hose-card" class="card mb-2 border-0">
            <a href="https://www.youtube.com/watch?v=GLhI9njTs_g"  target="_blank" class="mx-auto">
            <img
            src="/assets/images/new.jpg"
            width="500"
            class="img-fluid mx-auto">
          </a>
          </div>
        </div>
        <div class="col-lg-6">
          <div id="hose-card" class="card mb-2  border-0">
            <a href="https://www.youtube.com/watch?v=ywVPsAKO8yE&t=1s"  target="_blank" class="mx-auto">
            <img
            src="/assets/images/virtual.png"
            width="250"
            class="img-fluid mx-auto">
            </a>
          </div>
        </div>
      </div>
    </div>
    <mat-card id="moth-card">
      <mat-card-content>
        <h1 class="text-center">CLICK ON LOGO TO VISIT WEBSITE</h1>
      </mat-card-content>
     <mat-card class="inline-block" id="amg-eng">
      <a href="http://www.amgeng.co.za/" title="Lozdan and clark" target="_blank">
      <img
      width="150"
      alt="AMG-Logo"
      src="./assets/images/main-page/1.png"
    /></a>
     </mat-card>

     <mat-card class="inline-block" id="amg-mining">
      <a href="http://amgmining.amg-group.co.za/" title="Lozdan and clark" target="_blank">
      <img
      width="150"
      alt="CI-Logo"
      src="./assets/images/main-page/3.jpg"
    /></a>
     </mat-card>
     <mat-card class="inline-block" id="lozdan">
      <a href="http://www.lozdan.co.za/" title="Lozdan and clark" target="_blank">
      <img
      width="150"
      alt="CI-Logo"
      src="./assets/images/main-page/2.jpg"
    /></a>
     </mat-card>
     <hr>

    </mat-card>
    </div>
  </div>



