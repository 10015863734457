<div class="toolbar" role="main">
  <div class="toolbar" role="banner">

    <nav class="navbar navbar-expand-lg " >
      <img
      width="350"
      alt="AMG Logo"
      src="/assets/images/logo/logoportal.png"
      class="img-fluid d-flex mx-auto"
    />

      <button class="navbar-toggler" type="button"  data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup" >
        <div class="navbar-nav ml-auto">
          <ul class="nav navbar-nav">
            <li routerLinkActive="nav-link" class="nav-item dropdown dropdown " >
              <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
               role="button" aria-haspopup="true" aria-expanded="false" id="dropdown">Company Profiles</a>
               <div class="dropdown-menu ">
                <a class="dropdown-item" href="/assets/images/pdf/engineering.pdf" target="_blank"><p>AMG Engineering </p></a>
                <a class="dropdown-item" href="/assets/images/pdf/mining.pdf" target="_blank"><p>AMG Mining and Minerals </p></a>
                <a class="dropdown-item" href="/assets/images/pdf/group.pdf" target="_blank"><p>AMG Group Machining and Capabilities</p></a>
              </div>
            </li>
            <li routerLinkActive="nav-link"class="nav-item" ><a href="https://www.facebook.com/amggroup2020" target="_blank" class="nav-link"><i class="fa fa-facebook-official" aria-hidden="true"></i></a></li>
              <li routerLinkActive="nav-link"class="nav-item" ><a href=" https://www.youtube.com/channel/UCMXMGnlFHIr7xIZ1hiHB7aA" target="_blank" class="nav-link"><i class="fa fa-youtube-square" aria-hidden="true"></i></a></li>
                 <li routerLinkActive="nav-link"class="nav-item" ><a href=" https://youtu.be/GLhI9njTs_g" class="nav-link"><i class="fa fa-linkedin-square" aria-hidden="true"></i></a></li>


          </ul>
        </div>
      </div>
    </nav>
  </div>
  </div>

